import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Test() {
   const navigate = useNavigate();
   const [data,setData] = useState({
    username: "",
    uniqueId: "",
    message:"",
   });

   const handleregister = () =>{
    navigate('/register', { replace: true });
   } 
   
   const handlelogin = async(e) =>{
    e.preventDefault();
    if((data.username !== "") && (data.uniqueId !== "")){
      setData({...data,message:"Loading..."})
      try {
        const response = await axios.get(`https://bct-gamma.vercel.app/register/login/${data.uniqueId}`);
        if(response.status === 200 && response?.data!==""){
        localStorage.setItem('user', JSON.stringify(response?.data));
        navigate('/instructions', { replace: true });
      }
      else{
        setData({...data,message:"*Invalid Credentials"})
      }
        console.log(response);
      } catch (error) {
        setData({...data,message:"Server Error"})
        console.log(error)
      }
    }else{
      setData({...data,message:"*Invalid Credentials"})
    }
   
   }  
   
  return (
    <div>
      <div
        className="bg-cover bg-center h-[100vh]"
        style={{ backgroundImage: 'url("background.svg")' }}
      >
        <div className="w-[90vw] md:w-[80vw] h-[580px] mx-auto flex md:justify-between pt-[120px]">
          <div className="w-[350px] md:w-[35vw] h-[580px] bg-white rounded-lg p-[20px] md:p-[40px] space-y-10">
            <p className="text-[24px] md:text-[36px] text-[#025498] font-semibold pt-8 md:pt-0">
              Test Login
            </p>
            <form className="space-y-6">
              <div className="flex flex-col space-y-1">
                <label
                  id="user"
                  className="text-[16px] text-[#025498] font-semibold"
                >
                  Username
                </label>
                <input
                  type="text"
                  value={data.username}
                  name="username"
                  onChange={(e)=>setData({...data,[e.target.name]:e.target.value})}
                  className=" bg-[#F8F8F8] rounded-lg w-[310px] md:w-[400px] h-[40px] p-2"
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label
                  id="user"
                  className="text-[16px] text-[#025498] font-semibold"
                >
                  Unique ID
                </label>
                <input 
                  type="text"
                  value={data.uniqueId}
                  name="uniqueId"
                  onChange={(e)=>setData({...data,[e.target.name]:e.target.value})}
                  className=" bg-[#F8F8F8] rounded-lg  w-[310px] md:w-[400px] h-[40px] p-2"
                />
              </div>
              <div className="font-semibold flex flex-col justify-center items-center text-[16px] pt-10">
                <button className="bg-[#1D99FA] text-white  rounded-lg w-[160px] md:w-[200px] h-[40px] " onClick={handlelogin}>
                  Login
                </button>
                <p className="text-red-500">{data.message}</p>
                <p className="text-[#1D99FA] pt-10  text-[12px] font-light">
                  Not Registered yet?
                </p>
                <button className="text-[#1D99FA] mt-2 bg-white rounded-lg w-[160px] md:w-[200px] h-[40px] mx-auto  border-[#1D99FA] border-2 " onClick={handleregister}>
                  Register Now
                </button>
              </div>
            </form>
          </div>
          <div className="w-[65vw] hidden md:flex">
            <img src="test.png" alt="test" className="w-[680px] h-[580px]"/>
          </div>
        </div>
      </div>
    </div>
  );
}
