import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Instructions() {
  const { studentName } = JSON.parse(localStorage.getItem("user"));
  const [isChecked, setIsChecked] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const handleCheckboxChange = () => {
    setMsg("");
    setIsChecked(!isChecked);
  };

  const handleCheck = () => {
    isChecked ? navigate("/exam", { replace: true }) : setMsg("*");
  };

  return (
    <div>
      <div
        className="bg-cover bg-center h-[100vh] text-[#025498]"
        style={{ backgroundImage: 'url("background.svg")' }}
      >
        <div className="w-[100vw] md:w-[80vw] mx-auto pt-[80px]">
          <div className="bg-white rounded-lg w-[80vw] mx-auto h-fit p-5 md:p-6 shadow-md ">
            <p className="md:text-[48px] text-[32px] font-semibold capitalize  flex justify-center items-center">
              Welcome {studentName} !
            </p>

            <p className="text-[28px] md:text-[36px] font-semibold  pt-4">
              Boarding Compatibility Test
            </p>

            <p className="text-[18px] md:text-[24px] font-semibold  pt-6">
              Terms and Conditions
            </p>
            <div className="max-h-[270px] overflow-y-auto p-4">
              <div className="text-[16px] md:text-base space-y-4 ">
                <div>
                  Welcome to our Boarding School Compatibility Test. This test
                  is designed to evaluate various psychological aspects to
                  determine a child's suitability for a boarding school
                  environment. The results of this test are intended to be used
                  as a guide for a subsequent counselling session with a
                  qualified psychologist.
                </div>

                <div>
                  <b>Acceptance of Terms:</b> By participating in this test, you
                  agree to the following terms and conditions. Please read them
                  carefully before proceeding.
                </div>

                <div className="space-y-2">
                  <b>Test Instructions:</b>
                  <ol className="list-decimal list-inside space-y-1">
                    <li>
                      <b>One-Way Progress: </b>Once you submit an answer to a
                      question, you cannot change it. Please review your answers
                      carefully before moving to the next question.
                    </li>
                    <li>
                      <b>Linear Navigation:</b> You cannot browse through the
                      questions. Questions must be answered in the order they
                      are presented.{" "}
                    </li>
                    <li>
                      <b>No Time Limit: </b>There is no time limit for this
                      test. Take the time you need to consider each question
                      thoroughly.{" "}
                    </li>
                    <li>
                      <b>Save and Exit: </b>You have the option to save your
                      progress and exit the test at any point. You can resume
                      the test later from where you left off.{" "}
                    </li>
                    <li>
                      <b>Honest Responses:</b> For the most accurate assessment,
                      please answer each question honestly and to the best of
                      your ability.{" "}
                    </li>
                    <li>
                      <b>Privacy: </b>Your responses will be kept confidential
                      and will only be used for the purpose of this assessment
                      and the subsequent counselling session.
                    </li>
                  </ol>
                </div>

                <div className="space-y-2">
                  <b>Use of Test Results:</b>
                  <ol className="list-decimal list-inside space-y-1">
                    <li>
                      <b>Counselling Session:</b> The results of this test are
                      preliminary and are intended to guide a follow-up
                      counselling session with a psychologist.{" "}
                    </li>
                    <li>
                      <b>No Binding Decisions: </b>The test results are not
                      binding decisions. They should be used as part of a
                      broader assessment of the child's readiness for a boarding
                      school environment.{" "}
                    </li>
                    <li>
                      <b>Parental Involvement:</b> We strongly recommend
                      parental involvement in reviewing the results and in the
                      subsequent counselling session.
                    </li>
                  </ol>
                </div>

                <div>
                  <b>Liability Disclaimer: </b>While this test is designed to
                  provide helpful insights, it does not replace professional
                  psychological advice. We do not accept liability for any
                  decisions made based on the results of this test.{" "}
                </div>
                <div>
                  <b>Changes to Terms and Conditions: </b>We reserve the right
                  to modify these terms and conditions at any time. Continued
                  use of the test after such changes indicates your acceptance
                  of the new terms.
                </div>

                <div>
                  <b>Contact Information: </b>If you have any questions or
                  concerns regarding these terms and conditions, please contact
                  us at [Contact Information].
                </div>
              </div>
            </div>

            <div className="flex items-center pt-6">
              <input
                type="checkbox"
                id="agreeToTAndC"
                name="agreeToTAndC"
                className="form-radio  h-5 w-5"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="agreeToTAndC" className="ml-2">
                <span className="text-red-500">{msg}</span> By starting this
                test, you acknowledge that you have read, understood, and agreed
                to these Terms and Conditions.
              </label>
            </div>

            <div className="pt-8">
              <button
                className=" text-white text-base  rounded-lg w-[160px] md:w-[184px] h-[40px]  font-semibold"
                onClick={handleCheck}
                style={{ backgroundColor: "#1D99FA" }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
