import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return ( 
    <div className=" w-screen fixed h-[74px] z-50 top-0 mx-auto bg-gradient-to-r from-[#F2F4FB] to-[#FBF2F7]">
        <div className=' w-[100vw] md:w-[84vw] flex mx-auto  justify-between  px-5 pt-5 '>
            <div> 
                <Link to='https://www.boardingadmissions.com/'>
                <img src='logo.png' alt='logo' /></Link>
            </div>
            <div className=' hidden md:flex'>
                <ul className='flex gap-12 text-base font-semibold justify-center items-center text-[#352253]'>
                    <li><Link to='https://www.boardingadmissions.com/'>Home</Link></li>
                    <li><Link href="/">Courses</Link></li>
                    <li className='bg-[#1D99FA] text-white rounded-lg w-[180px] h-[40px] grid place-content-center'><a href='/test'>Check Compatibility</a></li>
                </ul>
                
            </div>
        </div>
    </div>
  )
}
