import React from 'react'

export default function Hero() {
  return (
    <div className="w-[100vw] md:w-[84vw] mx-auto px-5">
        <div className='flex flex-col md:flex-row gap-6 pt-[80px] md:pt-[160px] justify-between'>
            <div className='w-[320px] md:w-[380px] md:mt-[170px]'>
                <p className='text-[#025498] text-[36px] md:text-[64px] font-semibold'>Boarding Compatibility Test</p>
            </div>
            <div className='hidden md:flex w-[700px]'>
                <img src='hero.png' alt='hero' />
            </div>
            <div className='md:hidden'>
                <img src='mdhero.png' alt='hero' />
            </div>
        </div>
    </div>
  )
}
