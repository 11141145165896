import React from "react";
import { Link } from "react-router-dom";
export default function Success() {
  return (
    <div>
      <div
        className="bg-cover bg-center h-[100vh] flex justify-center items-center "
        style={{ backgroundImage: 'url("background.svg")' }}
      >
        <div className=" h-[80vh] w-[80vw] flex bg-white shadow-lg flex-col justify-evenly items-center rounded-lg text-[#025498]">
          <div className="mt-10">
            <img
              src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f973/512.gif"
              alt="🥳"
              width="256"
              height="256"
            ></img>
          </div>

          <div className="flex flex-col justify-center gap-5 items-center">
            <p className="text-[36px] font-semibold">Wohoo.. </p>
            <p className="text-[24px]">you’ve completed your test</p>
            <Link className="bg-[#1D99FA] text-base text-[#FFFFFF] text-center p-2 rounded-lg w-[160px] md:w-[184px] h-[40px] font-semibold"
               to="https://www.boardingadmissions.com/">
                Back to Home
            </Link>
            <Link className="bg-[#1D99FA] text-base text-[#FFFFFF] text-center p-2 rounded-lg w-[270px] md:w-[300px] h-[40px] font-semibold" to="https://compatibilitytest.boardingadmissions.com/">
                Boarding Compatibility Test
              </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
