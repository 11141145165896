import React from "react";
import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";
import { FiPhone } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className=" bg-[#025498] flex flex-col gap-4 h-fit mt-5 " style={{ fontFamily: 'Jost, sans-serif'}}>
      <div className="block sm:flex justify-around gap-10 p-6 md:p-10  text-white ">
        <div className="flex flex-col gap-3 lg:w-[25vw] md:justify-center md:text-center md:items-center  ">
          <img
            className="w-[223px] h-[84px] flex items-center justify-center"
            src="footerlogo.png"
            alt=""
          />
          <div className="text-3xl text-white flex flex-col md:flex-row absolute right-10 md:relative md:right-0 gap-10 md:justify-center mt-4 md:items-center md:mt-8">
{/*             <Link to="https://twitter.com/edumynation" target="_blank">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                fill="white"
                viewBox="0 0 512 512"
              >
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </Link> */}
            <Link to="https://www.facebook.com/people/Boarding-Admissions/100093512793631/" target="_blank">
              <BsFacebook size={26}/>
            </Link>
{/*             <Link
              to="https://www.linkedin.com/company/edu-my-nation/about/"
              target="_blank"
            >
              <BsLinkedin size={26}/>
            </Link> */}
            <Link to="https://www.instagram.com/boarding_admissions/" target="_blank">
              <BsInstagram size={26}/>
            </Link>
          </div>
        </div>

        <div className="flex md:w-[50vw] pt-6 md:pt-0">

        <div className="font-semibold text-[12px] md:text-[16px] md:w-[35vw] flex flex-col  space-y-6">
          <div>
            <p className="mt-4 text-left text-[16px] md:text-[20px]"> Official info</p>
          </div>
          <div className="flex flex-col gap-3">
          <div className=" flex gap-3 ">
            <GoLocation size={20} />
            <div>
              <p>Nehru Colony, Dehradun</p>
            </div>
          </div>
          <div className="flex items-center gap-3 ">
            <HiOutlineMail  size={20}  />
            <Link to="mailto:info@boardingadmission">info@boardingadmission.com</Link>
          </div>
          <div className="flex items-center gap-3 ">
            <FiPhone  size={20}  />
            <Link to="tel:+9149057322" target="_blank">
            9149057322
            </Link>
          </div>
          </div>
        </div>

        <div className="  md:w-[30vw] text-[20px]  font-semibold  hidden sm:block  space-y-6">
          <div>
            <p className="text-white my-4 text-left">
              {" "}
              Links
            </p>
          </div>
          <div className="flex flex-col gap-3 text-left text-[16px] ">
              <Link to='https://www.boardingadmissions.com/'>Home</Link>
              <Link  to={"/"}>Courses & Packages</Link>
              <Link to='https://compatibilitytest.boardingadmissions.com/'>Boarding Compatibility Test</Link>
          </div>
        </div> 

        <div className=" md:w-[30vw] text-[20px]  font-semibold hidden sm:block  space-y-6">
          <div>
            <p className="text-white my-4 text-left">
              {" "}
              Resources
            </p>
          </div>
          <div className="flex flex-col gap-3 text-left text-[16px] ">
              <Link to={"/"}>Sample Classes</Link>
              <Link to={"/"}>Sample Test Papers</Link>
          </div>
        </div>
        </div>
      </div>

      <div className="text-[12px] font-semibold text-center text-white pb-4">
        <p><span className="font-light">Copyright 2023 </span>Boarding Admissions</p>
        <p><span className="font-light">by</span> DIgital360</p>
      </div>
    </div>
  );
}
