import React from "react";
import { Link } from "react-router-dom";

export default function Thankyou() {
  return (
    <div>
      <div>
        <div
          className="bg-cover bg-center h-[100vh] flex justify-center items-center "
          style={{ backgroundImage: 'url("background.svg")' }}
        >
          <div className=" h-[80vh] w-[80vw] flex bg-white shadow-lg flex-col justify-evenly items-center rounded-lg text-[#025498]">
            <div className="mt-10 text-center space-y-5 ">
              <span className=" text-center text-6xl ">Thank You</span>
              {/* <img
                src="https://media.tenor.com/r1sbNZlVEVgAAAAC/thank-you-minion.gif"
                alt="🥳"
                width="256"
                height="256"
              ></img> */}
               <p className=" text-[20px] md:text-[28px] text-[#1D99FA]">
                 We are verifying your payment status. You will receive a test link soon...
              </p>
            </div>

            <div className="flex flex-col ">
              {/* <p className="text-[64px] text-[#1D99FA] font-semibold">
                Thank You !
              </p> */}
             
              <Link
                className="bg-[#1D99FA] text-base text-[#FFFFFF] text-center p-2 rounded-lg w-[160px] md:w-[184px] h-[40px] font-semibold"
                to="https://www.boardingadmissions.com/"
              >
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
