import React from 'react'
import Hero from '../component/Hero'
import About from '../component/About'
import TestCategories from '../component/TestCategories'

export default function Home() {
  return (
    <div>
        <Hero />
        <About />
        <TestCategories />
    </div>
  )
}
