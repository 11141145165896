import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./component/Navbar";
import Home from "./pages/Home";
import TestLogin from "./pages/TestLogin";
import Register from "./pages/Register";
import Thankyou from "./pages/Thankyou";
import Paper from "./pages/Paper";
import Instructions from "./pages/Instructions";
import Success from "./pages/Success";
import Footer from "./component/Footer";
import Resume from "./pages/Resume";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/test" element={<TestLogin />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/thankyou" element={<Thankyou />} />
          <Route exact path="/exam" element={<Paper />} />
          <Route exact path="/instructions" element={<Instructions />} />
          <Route exact path="/success" element={<Success />} />
          <Route exact path="/resume" element={<Resume />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
