import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Payment from "../assets/Payment.jpeg";
export default function Register() {
  const schoolOptions = [
    "Welham Boys School",
    "Welham Girls School",
    "Ecole Globale International Girls School",
    "Mayo College",
    "Other",
  ];

  const [formData, setFormData] = useState({
    studentName: "",
    parentName: "",
    mobile: "",
    email: "",
    dob: "",
    city: "",
    grade: "",
    school: "",
    targetSchool: "",
    txnId: "",
    next: 1,
    message: "",
    userRegistered: false,
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    if(formData.userRegistered){
      return;
    }

    setFormData({ ...formData, message: "" });
    console.log(formData);
    e.preventDefault();
    if (
      formData.studentName !== "" &&
      formData.parentName !== "" &&
      formData.mobile !== "" &&
      formData.email !== "" &&
      formData.dob !== "" &&
      formData.grade !== "" &&
      formData.city !== "" &&
      formData.school !== "" &&
      formData.targetSchool !== "" &&
      formData.txnId !== ""
    ) {
      try {
        setFormData((prevPaper) => ({
          ...prevPaper,
          userRegistered: true,
        }));

        delete formData.next;
        const response = await axios.post(
          "https://bct-gamma.vercel.app/register",
          formData
        );
        if (response.status === 200) {
          navigate("/thankyou", { replace: true });
        }
        console.log(response.data);
      } catch (error) {
        console.error("Error submitting the form:", error);
      }
    } else {
      setFormData({ ...formData, message: "* All Fields Are Mandatory" });
    }
  };

  return (
    <div>
      <div
        className="bg-cover bg-center lg:h-[100vh] hi "
        style={{ backgroundImage: 'url("background.svg")' }}
      >
        <form
          className="w-[100vw] md:w-[80vw] mx-auto pt-[80px] p-5"
          onSubmit={handleFormSubmit}
        >
          {formData.next == 1 && (
            <div className="bg-white rounded-lg h-full p-5 md:p-10 ">
              <p className="text-[36px] font-semibold text-[#025498] pt-4">
                Registration Form
              </p>

              <div className="space-y-2 pt-8">
                <p className="text-[24px] font-semibold text-[#025498]">
                  Primary Details
                </p>

                <div className="flex flex-wrap justify-between text-[16px] space-y-2">
                  <div className="flex flex-col space-y-1 pt-2">
                    <label id="name" className=" text-[#025498]">
                      Name
                    </label>
                    <input
                      type="text"
                      name="studentName"
                      value={formData.studentName}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label id="parentname" className=" text-[#025498] ">
                      Parent Name
                    </label>
                    <input
                      type="text"
                      name="parentName"
                      value={formData.parentName}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label id="mobile" className=" text-[#025498] ">
                      Mobile
                    </label>
                    <input
                      type="tel"
                      name="mobile"
                      pattern="[0-9]{10}"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label id="email" className=" text-[#025498] ">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label id="dob" className=" text-[#025498] ">
                      DOB
                    </label>
                    <input
                      type="date"
                      name="dob"
                      value={formData.dob}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label id="city" className="text-[#025498] ">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-2 pt-8">
                <p className="text-[24px] font-semibold text-[#025498]">
                  Educational Details
                </p>

                <div className="flex flex-wrap justify-between text-[16px] space-y-2">
                  <div className="flex flex-col space-y-1 pt-2">
                    <label id="grade" className=" text-[#025498] ">
                      Grade
                    </label>
                    <input
                      type="number"
                      name="grade"
                      placeholder="Enter class  { eg : 1,2,3...}"
                      min={1}
                      max={12}
                      value={formData.grade}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label id="school" className=" text-[#025498] ">
                      School
                    </label>
                    <input
                      type="text"
                      name="school"
                      placeholder="Your Current School"
                      value={formData.school}
                      onChange={handleInputChange}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label id="targetschool" className="text-[#025498]">
                      Target School
                    </label>
                    <input
                      type="text"
                      name="targetSchool"
                      value={formData.targetSchool}
                      onChange={handleInputChange}
                      className="bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                </div>
              </div>
              <div className="h-[80px]  pt-10">
                <button
                  type="button"
                  onClick={() =>
                    setFormData({ ...formData, next: formData.next + 1 })
                  }
                  className=" text-white  rounded-lg w-[160px] md:w-[200px] h-[40px] "
                  style={{ backgroundColor: "#1D99FA" }}
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {formData.next == 2 && (
            <div className="bg-white rounded-lg hi   p-5 md:p-10 ">
              <p className="text-[36px] font-semibold text-[#025498] pt-4">
                Payment
              </p>

              <div className="space-y-2 pt-8">
                <div>
                  <img src={Payment} className="h-[250px] w-[210px]" alt="" />
                </div>
              </div>
              <div className="space-y-2 pt-8">
                <div className="flex flex-wrap justify-between text-[16px] space-y-2">
                  <div className="flex flex-col space-y-1">
                    <label id="school" className=" text-[#025498] ">
                      Transaction ID
                    </label>
                    <input
                      type="text"
                      name="txnId"
                      placeholder="Enter Last 6 characters of your Txn-Id  "
                      value={formData.txnId}
                      onChange={handleInputChange}
                      maxLength={6}
                      className=" bg-[#F8F8F8] rounded-lg w-[300px] h-[40px] p-2"
                    />
                  </div>
                </div>
              </div>

              <div className="hi  space-y-2 md:space-y-0 space-x-2  pt-10">
                <p className=" text-red-500">{formData.message}</p>
                <button
                  type="submit"
                  className=" text-white  rounded-lg w-[160px] md:w-[200px] h-[40px] "
                  style={{ backgroundColor: "#1D99FA" }}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() =>
                    setFormData({ ...formData, next: formData.next - 1 })
                  }
                  className=" text-white  rounded-lg w-[160px] md:w-[200px] h-[40px] "
                  style={{ backgroundColor: "#1D99FA" }}
                >
                  Back
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
